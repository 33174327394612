import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SimpleShowLayout,
  Show,
  useUnique,
  TextInput,
  required,
  email,
  Edit,
  SimpleForm,
  EditButton,
  ReferenceField,
} from 'react-admin'
import { DEFAULT_SORT, DEFAULT_PER_PAGE } from 'admin/utils/react-admin'
import constants from 'utils/constants'
import EditToolbar from 'admin/components/EditToolbar'
import MergeButton from 'admin/components/MergeButton'
import DateTimeField from 'admin/components/DateTimeField'

export const EmployeesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="phone_number" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ArrayField source="agencies">
        <Datagrid resource="agencies" bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="status" />
          <ReferenceField source="agent_id" reference="agents" link="show" label="Agent">
            <TextField source="first_name" /> <TextField source="last_name" />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
      <ArrayField source="documents" resource="documents">
        <Datagrid resource="documents" bulkActionButtons={<MergeButton />}>
          <DateTimeField source="created_at" />
          <TextField source="label" />
          <TextField source="verification_status" />
          <TextField source="expiration_date" />
          <EditButton resource="documents" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)

export const EmployeesList = () => (
  <List sort={DEFAULT_SORT} perPage={DEFAULT_PER_PAGE}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="phone_number" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

const EmployeeInputs = () => {
  const unique = useUnique()
  return (
    <>
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <TextInput source="email" type="email" validate={[required(), email(), unique()]} />
      <TextInput source="phone_number" type="phone_number" validate={[unique()]} />
    </>
  )
}

export const EmployeeEdit = () => (
  <Edit mutationMode={constants.IS_TEST_ENV ? 'pessimistic' : 'undoable'}>
    <SimpleForm toolbar={<EditToolbar />}>
      <EmployeeInputs />
    </SimpleForm>
  </Edit>
)

type Employee = {
  id: string
  first_name: string
  last_name: string
  email: string
  created_at: string
  updated_at: string
}

export const employeeRecordRepresentation = (record: Employee) =>
  `${record.first_name} ${record.last_name} (${record.id})`
