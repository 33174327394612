import React from 'react'
import { useStats, VerificationStats } from 'admin/queries/stats'

type ListVerificationStatsProps = {
  verificationStats: VerificationStats
}

const ListVerificationStats = ({ verificationStats }: ListVerificationStatsProps) => (
  <ul>
    <li>Nombre de documents marqués OK par OpenAI : {verificationStats.verification_ok_by_openai}</li>
    <li>Nombre de documents marqués OK par Mindee : {verificationStats.verification_ok_by_mindee}</li>
    <li>Nombre de documents marqués conformes par CTMS : {verificationStats.verification_compliant_by_ctms}</li>
    <li>Nombre de documents marqués OK par un admin : {verificationStats.verification_ok_by_admin}</li>
    <li>% de vérification automatique : {verificationStats.percentage_auto}%</li>
  </ul>
)

const Stats = () => {
  const { data: stats } = useStats()

  if (!stats) return null
  return (
    <div className="mt-8 p-4 bg-white shadow rounded-lg">
      <p>Nombre de documents total : {stats.all_documents}</p>
      <p>Nombre de documents affichés sur Skribe (current documents) : {stats.current_documents}</p>
      <p>Nombre de documents pas OK (fraude, illisible, etc.) : {stats.not_ok_documents}</p>
      <p>Nombre d&apos;emails envoyés aux intéraimres : {stats.emails_sent_to_employee}</p>

      <h2 className="pt-4 pb-2 font-bold text-lg">Invitations</h2>
      <p>Nombre d&apos;invitations envoyées : {stats.invitation_sent}</p>
      <p>Nombre d&apos;invitations acceptées : {stats.invitation_accepted}</p>

      <h2 className="pt-4 pb-2 font-bold text-lg">Dossiers</h2>
      <p>Nombre de dossier complets : {stats.complete_folders}</p>
      <p>Nombre de dossiers avec 3 documents ou plus : {stats.folders_with_3_or_more_documents}</p>
      <p>Nombre de dossiers frauduleux : {stats.fraudulent_folders}</p>

      <h2 className="pt-4 pb-2 font-bold text-lg">Vérification de documents (depuis le début)</h2>
      <ListVerificationStats verificationStats={stats.verification.all_time} />

      <h2 className="pt-4 pb-2 font-bold text-lg">Vérification de documents (14 derniers jours)</h2>
      <ListVerificationStats verificationStats={stats.verification.last_14_days} />
    </div>
  )
}

export default Stats
