import React from 'react'
import Button from 'components/Button'
import Input from 'components/Input'
import constants from 'utils/constants'
import { csrfToken } from 'utils/http-client'

const DevLogin = () => (
  <form className="mt-8 flex" method="post" action="/auth/developer/callback">
    <input type="hidden" name="authenticity_token" value={csrfToken()} />
    <Input name="email" type="email" placeholder="email" className="w-64" />
    <Button type="submit" name="dev_login" uiStyle="secondary" className="w-64 text-center">
      Dev login
    </Button>
  </form>
)

const Login = () => (
  <div>
    {constants.IS_TEST_ENV ? <DevLogin /> : null}
    <form method="post" action="/auth/google_oauth2?origin=/">
      <input type="hidden" name="authenticity_token" value={csrfToken()} />
      <Button type="submit" name="google_login" uiStyle="secondary" className="w-64 text-center">
        Se connecter avec Google
      </Button>
    </form>
  </div>
)

export default Login
